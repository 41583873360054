import React from "react";

const Header = () => {
  return (
    <div className="content-wrap body">
      <h2 className="h2">Full Stack Web Developer</h2>
      <p className="indent">
        I am a seasoned professional with 15+ years of software development,
        technology solutions, and Agile project delivery experience.
      </p>
      <p className="indent">
        My programming experience started with{" "}
        <a href="http://en.wikipedia.org/wiki/BASIC" target="_blank">
          BASIC
        </a>{" "}
        back in the days, on a{" "}
        <a href="http://en.wikipedia.org/wiki/ZX_Spectrum" target="_blank">
          ZX Spectrum
        </a>{" "}
        home computer. Now that was really fun, using an actual black and white
        TV and a tape-player/recorder to load and save "software".
      </p>
      <p className="indent">
        The next step was microcontroller programming for a few applications
        with PIC and ATMEL controllers. At this point I really enjoyed
        programming and I was looking forward to learn some higher level
        languages.
      </p>
      <p className="indent">
        During my high school years I've got introduced to{" "}
        <a href="http://en.wikipedia.org/wiki/ANSI_C" target="_blank">
          ANSI C
        </a>{" "}
        programming, which turned out to be quite a different approach and
        somehow changed the definition of programming. This was followed by
        <a
          href="http://en.wikipedia.org/wiki/Object-oriented_programming"
          target="_blank"
        >
          {" "}
          Object Oriented Programming
        </a>
        , C++ with all the new - sometimes twisted - approaches and at this
        point it was clear to me, that I am walking a path without a target,
        that programming is a lifelong learning process which demands an
        all-time open mindedness and willingness to make the step which doesn't
        necessarily gets you closer to any target but definitely takes you out
        from your last comfort zone.
      </p>
      <p className="indent">
        While at university I learnd about{" "}
        <a href="http://en.wikipedia.org/wiki/Unix-like" target="_blank">
          *nix
        </a>{" "}
        systems, databases and different scripting and mark-up languages. Cool
        stuff. I became a big fan of Open Source Software and also I have a
        great respect for people who develop code for the sake of creating some
        useful tools for the humanity, without any commercial considerations.
        Guys, you might never read this, but still, thumbs up!
      </p>
      <p className="indent">
        Later on, the{" "}
        <a href="http://en.wikipedia.org/wiki/World_Wide_Web" target="_blank">
          World Wide Web
        </a>{" "}
        started to develop really fast, opening new fascinating opportunities
        for the programmers and myself of course. I got from writing code
        through programming machines and devices to do stuff, to actual software
        development and right now enjoying WEB application development.
      </p>
    </div>
  );
};

export default Header;
