import React from "react";
import profileImg from "../../assets/img/profile-pic.png";

const Content = () => {
  return (
    <div className="profile-img">
      <img className="profile-img" src={profileImg} alt="yo6gzi" />
    </div>
  );
};

export default Content;
