import React from "react";

const Header = () => {
  return (
    <div className="content-wrap body">
      <h2 className="h2">Welcome!</h2>
      <p className="indent">
        I have created this page a long time ago as a small weekend project for
        the sake of leaving a personal footprint on the world wide web and to
        experiment with some JavaScript frameworks.
      </p>
      <p className="indent">
        What you will find here is basically a collection of static pages, since
        my life is not hectic enough to provide the necessary input for a
        blog-like entry. There is some brief information about my two main
        hobbies, one of them being also my profession (I let you guess which
        one).
      </p>
    </div>
  );
};

export default Header;
