import React from "react";

const LogInPageHeader = () => {
  require("../../assets/css/styles.css");

  return (
    <div className="content-wrap body">
      <h2 className="h2">Authenticate</h2>
    </div>
  );
};

export default LogInPageHeader;
