export const topMenuItems = [
  {
    name: "Home",
    url: "/home",
    icon: "",
    enabled: true,
  },
  {
    name: "Radio",
    url: "/radio",
    icon: "",
    enabled: true,
  },
  {
    name: "Code",
    url: "/code",
    icon: "",
    enabled: true,
  },
  {
    name: "Crypto",
    url: "/crypto",
    icon: "",
    enabled: false,
  },
];

export const accountCircleMenuItems = [
  {
    name: "Account",
    url: "",
    icon: "",
    enabled: false,
  },
  {
    name: "Authenticate",
    url: "",
    icon: "",
    enabled: true,
  },
];
