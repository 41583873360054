import LogInPageHeader from "./LogInPageHeader";
import LogInPageContent from "./LogInPageContent";

const LogInPage = () => {
  return (
    <>
      <LogInPageHeader />
      <LogInPageContent />
    </>
  );
};

export default LogInPage;
