import React from "react";
import AppRouter from "./AppRouter";
import "./App.css";

export default function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}
